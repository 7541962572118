jQuery(document).ready(function($){
	if( $(window).width() > 800 ){
		$(function init(){
			$(".kia").each(function(){
				var $delay = $(this).attr("data-kia-delay");
				var $duration = $(this).attr("data-kia-duration");
				$(this).css({"transition-delay": $delay, "transition-duration": $duration});
			});
		});

		setTimeout(function(){
			$(".kia").each(function(){
				var $this = $(this);
				$(window).scroll(function(){
					if( ($(window).scrollTop() + $(window).height() - 100) > $($this).offset().top ){
						$($this).addClass("kia-animate");
					}
				});
				if( ($(window).scrollTop() + $(window).height() - 100) > $($this).offset().top ){
					$($this).addClass("kia-animate");
				}
			});
		}, 500);
	}

	$(window).resize(function(){
		if( $(window).width() > 800 ){
			$(function init(){
				$(".kia").each(function(){
					var $delay = $(this).attr("data-kia-delay");
					var $duration = $(this).attr("data-kia-duration");
					$(this).css({"transition-delay": $delay, "transition-duration": $duration});
				});
			});

			setTimeout(function(){
				$(".kia").each(function(){
					var $this = $(this);
					$(window).scroll(function(){
						if( ($(window).scrollTop() + $(window).height() - 100) > $($this).offset().top ){
							$($this).addClass("kia-animate");
						}
					});
					if( ($(window).scrollTop() + $(window).height() - 100) > $($this).offset().top ){
						$($this).addClass("kia-animate");
					}
				});
			}, 500);
		}
	});
});

jQuery(document).load(function($){
	$(".kia").each(function(){
		var $this = $(this);
		if( ($(window).scrollTop() + $(window).height() - 100) > $($this).offset().top ){
			$($this).addClass("kia-animate");
		}
	});
});

//
// ** Add animation to responsive menu list items
//
jQuery(function($){
	var $liCount = 10;
	var $addrCount = 10;
	var $li = $(".ci-navigation-menu .main-nav li");
	var $addr = $(".ci-navigation-menu .address");

	$(".ci-navigation-menu .main-nav li:first").attr("data-kia-delay", "0.10s");

	$li.each(function(){
		$(this).addClass("kia fade-right").attr("data-kia-delay", "0." + $liCount + "s");
		$liCount+=5;
		setTimeout(function(){
			$li.removeClass("kia-animate");
		}, 500);
	});

	$addr.each(function(){
		$(this).addClass("kia fade-left").attr("data-kia-delay", "0." + $addrCount + "s");
		$addrCount+=5;
		setTimeout(function(){
			$addr.removeClass("kia-animate");
		}, 500);
	});

	$(".ci-menu").click(function(){
		if( ! $(this).hasClass("active") ){
			setTimeout(function(){
				$li.each(function(){
					$(this).addClass("kia-animate");
				});
				$addr.each(function(){
					$(this).addClass("kia-animate");
				});
			}, 150);
		} else {
			$li.each(function(){
				$(this).removeClass("kia-animate");
			});
			$addr.each(function(){
				$(this).removeClass("kia-animate");
			});
		}
	});
});